import axios from "axios"
import { useState, useEffect } from "react"
import { useNavigate } from 'react-router-dom'
import Swal from "sweetalert2"
import { getToken } from "../services/authorize"
import { formatRegNameOnly } from "../function/stringUtils"


const RegisterNoDocComponent = () => {
  const navigate = useNavigate()

  const [regName, setRegName] = useState('')
  const [regPhone, setRegPhone] = useState('')
  const [regClass, setRegClass] = useState('')
  const [regType, setRegType] = useState('')
  const [isChooseType, setIsChooseType] = useState(true)

  useEffect(() => {
    regType !== "" ? setIsChooseType(false) : setIsChooseType(true)
  }, [regType])

  // check user choose junior or high school
  const showClassToChoose = () => {
    if(regType === 'high') {
      return (
        <>
          <option value="h4">ม. 4</option>
          <option value="h5">ม. 5</option>
          <option value="h6">ม. 6</option>
        </>
      )
    } else if (regType === 'junior') {
      return (
        <>
          <option value="h1">ม. 1</option>
          <option value="h2">ม. 2</option>
          <option value="h3">ม. 3</option>
        </>
      )
    } else if (regType === 'anubal') {
      return (
        <>
          <option value="a1">อ. 1</option>
          <option value="a2">อ. 2</option>
          <option value="a3">อ. 3</option>
        </>
      )
    } else if (regType === 'prathom') {
      return (
        <>
          <option value="p1">ป. 1</option>
          <option value="p2">ป. 2</option>
          <option value="p3">ป. 3</option>
          <option value="p4">ป. 4</option>
          <option value="p5">ป. 5</option>
          <option value="p6">ป. 6</option>
        </>
      )
    } 
  }

  const [regBy, setRegBye] = useState('')
  useEffect(() => {
    if (sessionStorage.getItem('user')) {
      // Parse the 'user' object from JSON
      const user = JSON.parse(sessionStorage.getItem('user'));
      setRegBye(user.t_name)
      // console.log(user)
    }
  }, [])

  const submitForm = (e) => {
    e.preventDefault()
    axios.post(`${process.env.REACT_APP_API}/registerNoDoc/add`,{ regName, regPhone, regType, regClass, regBy },
    {
      headers: {
          authorization: `Bearer ${getToken()}`
      }
    })
    .then(response => {
      Swal.fire({
        title: response.data.message,
        text: "เลขประจำตัว " + response.data.st_idnumber,
        icon: "success"
      }).then(() => {
        regType === 'junior' || regType === 'high' ? navigate(`/printMatthayom/${response.data.st_id}`) : navigate(`/printPrathom/${response.data.st_id}`)
      })
    })
    .catch(err =>{
      if (err.code === "ERR_NETWORK") {
        Swal.fire({
          title: "Network Error",
          text: "ไม่สามารถเชื่อมต่อได้ กรุณาตรวจสอบอินเทอร์เน็ต",
          icon: "error"
        })
      } else {
        Swal.fire({
          title: "error",
          text: err.response.data.message,
          icon: "error"
        })
      }
      console.log(err)
    })
  }

  return (
    <div className="pt-4 ps-4 pe-4 mt-5 mb-4">
      <div className="container-fluid">
        <div className="row box-content rounded">
          {/* header of main content */}
          <h4 className="text-dark fw-bold">
            <i className="bi bi-person-add me-2"></i>
            สมัครนักเรียนกรณีไม่มีหลักฐาน เพื่อกรอกข้อมูลเอง
          </h4>
          <hr className="text-dark mt-1 mb-3" />
          {/* body of main content */}

          <form onSubmit={submitForm}>
            <div className="row mb-3 ps-1 pe-1">
              <label htmlFor="inputName" className="col-sm-2 col-form-label">ผู้สมัคร ชื่อ</label>
              <div className="col-sm-4 p-2">
                <input type="text" className="form-control" id="inputName" value={regName} onChange={(e) => setRegName(e.target.value)} onBlur={(e) => formatRegNameOnly(e.target.value, setRegName)} placeholder="ชื่อเต็มผู้สมัคร" required/>
              </div>
              <label htmlFor="inputBirthdate" className="col-sm-2 col-form-label">เบอร์โทรศัพท์</label>
              <div className="col-sm-4 p-2">
                <input type="text" className="form-control" id="inputBirthdate" value={regPhone} onChange={(e) => setRegPhone(e.target.value)} placeholder="ระบุเบอร์โทรศัพท์" required/>
              </div>
            </div>
            <div className="row mb-3 ps-1 pe-1">
                <label className="col-sm-2 col-form-label">สมัครเรียนระดับ</label>
                <div className="btn-group col-sm-4 p-2" role="group">
                  <input type="radio" className="btn-check" name="chooseClass" id="chooseClass1" value="anubal" checked={regType === 'anubal'} onChange={(e) => setRegType(e.target.value)} autoComplete="off" required/>
                  <label className="btn btn-outline-primary" htmlFor="chooseClass1">อนุบาล</label>
                  <input type="radio" className="btn-check" name="chooseClass" id="chooseClass2" value="prathom" checked={regType === 'prathom'} onChange={(e) => setRegType(e.target.value)} autoComplete="off"/>
                  <label className="btn btn-outline-primary" htmlFor="chooseClass2">ประถม</label>
                  <input type="radio" className="btn-check" name="chooseClass" id="chooseClass3" value="junior" checked={regType === 'junior'} onChange={(e) => setRegType(e.target.value)} autoComplete="off"/>
                  <label className="btn btn-outline-primary" htmlFor="chooseClass3">ม. ต้น</label>
                  <input type="radio" className="btn-check" name="chooseClass" id="chooseClass4" value="high" checked={regType === 'high'} onChange={(e) => setRegType(e.target.value)} autoComplete="off"/>
                  <label className="btn btn-outline-primary" htmlFor="chooseClass4">ม. ปลาย</label>
                </div>
                <label htmlFor="inputChooseRoom" className="col-sm-2 col-form-label">ชั้น</label>
                <div className="col-sm-4 p-2">
                  <select className="form-control" value={regClass} onChange={(e) => setRegClass(e.target.value)} id="inputChooseRoom" required disabled={isChooseType}>
                    <option value="">-เลือกชั้นเรียน-</option>
                    {showClassToChoose()}
                  </select>
                </div>
              </div>
              <div className="row">
                <div align="right">
                  <button type="submit" className="btn btn-success"><i className="bi bi-check-lg me-2"></i>ยืนยัน</button>
                </div>
              </div>
          </form>
        </div>
      </div>
    </div>
  )

}

export default RegisterNoDocComponent