//เก็บข้อมูล token / username ลงใน session storage

export const authenticate = (response, next) => {
  if(window !== 'undefined'){
    //store data in session storage
    sessionStorage.setItem('token', JSON.stringify(response.data.token))
    sessionStorage.setItem('user', JSON.stringify(response.data.user))
  }
  next() // user togather with useNavigate from react-router-dom
}

//ดึงข้อมูล token
export const getToken = () => {
  if(window !== 'undefined'){
    // เช็คก่อนว่ามีการสร้างกล่อง Token อยู่รึป่าว
    if(sessionStorage.getItem('token')){
      return JSON.parse(sessionStorage.getItem('token'))
    }else{
      return false
    }
  }
}

// ดึงข้อมูล user
export const getUser = () => {
  if(window !== 'undefined'){
    // เช็คก่อนว่ามีการสร้างกล่อง user อยู่รึป่าว
    if(sessionStorage.getItem('user')){
      return JSON.parse(sessionStorage.getItem('user'))
    }else{
      return false
    }
  }
}

// Logout
export const logout = (next) => {
  if(window !== 'undefined'){
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('user')
  }
  next() // use togather with useNavigate from react-router-dom
}