import { useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Swal from 'sweetalert2'
import { getToken } from '../services/authorize'

const ListPrathomComponent = () => {

  const [registerData, setRegisterData] = useState('')

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API}/registerPrathom/list`,
    {
      headers: {
          authorization: `Bearer ${getToken()}`
      }
    })
    .then(response => {
      setRegisterData(response.data)
    })
    .catch(err => {
      console.log(err)
      Swal.fire({
        title: err.response.statusText,
        text: err.message,
        icon: "error"
      })
    })
  },[])

  return (
    <div className="pt-4 ps-4 pe-4 mt-5 mb-4">
      <div className="container-fluid">   
        <div className="row box-content rounded">
          {/* header of main content */}
          <h4 className="text-dark fw-bold">
            <i className='bi bi-file-person me-2'></i>
            รายชื่อผู้สมัครประถม
          </h4>
          <hr className='text-dark mt-1 mb-3'/>
          {/* body of main content */}
          <table className="table table-hover">
            <thead>
              <tr className="table-dark">
                <th scope="col" className="text-center" style={{width: '10%'}}>เลขประจำตัว</th>
                <th className="text-center" style={{width: '15%'}}>เลขประจำตัวประชาชน</th>
                <th scope="col" style={{width: '25%'}}>ชื่อ-นามสกุล</th>
                <th scope="col" className="text-center" style={{width: '15%'}}>ระดับ</th>
                <th scope="col" className="text-center" style={{width: '5%'}}>ชั้น</th>
                <th scope="col" className="text-center" style={{width: '10%'}}>รับสมัครโดย</th>
                <th style={{width: '20%'}}></th>
              </tr>
            </thead>
            <tbody>
            {registerData && registerData.length > 0 ? (
            registerData.map((data, index) => (
              <tr key={data.st_id}>
                <th scope="row" className="text-center text-primary">{data.st_idnumber}</th>
                <td className="text-center">{data.st_idcard}</td>
                <td>{data.st_name}</td>
                <td className="text-center">{data.regis_academicStudy}</td>
                <td className="text-center">{data.regis_academicClass}</td>
                <td className="text-center">{data.regis_by}</td>
                <td className="text-center">
                  <Link className='btn btn-primary btn-sm me-1' to={`/updateRegister/prathom/${data.st_id}`}><i className='bi bi-pencil-square me-1'></i>แก้ไข</Link>
                  <Link className='btn btn-success btn-sm' to={`/printPrathom/${data.st_id}`}><i className='bi bi-printer me-1'></i>ปริ้นเอกสาร</Link>
                </td>
              </tr>
              ))
              ) : (
                <tr>
                  <td colSpan="8" className="text-center">Loading...</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default ListPrathomComponent