// cut prefix function
export const reAssignAddress = (address, setState, state, who) => {
  let value = address.replace(/(?<!\s)หมู่/g, ' หมู่').replace(/(?<!\s)ตรอก/g, ' ตรอก').replace(/(?<!\s)ซอย/g, ' ซอย').replace(/(?<!\s)ถนน/g, ' ถนน')
  switch(who){
    case 'reg':
      setState({...state, regAddress: value})
      break
    case 'dad':
      setState({...state, dadAddress: value})
      break
    case 'mom':
      setState({...state, momAddress: value})
      break
    case 'parent':
      setState({...state, parentAddress: value})
      break
    default:
      setState(...state)
      break
  }
}

export const cutSubDistrict = (subDistrict, setState, state, who) => {
  let value = subDistrict
  // Remove the prefix 'จังหวัด' from the address
  if (value.startsWith('ตำบล')) {
    value = value.substring(4); // Remove the first 5 characters
  }
  switch(who){
    case 'reg':
      setState({...state, regSubDistrict: value})
      break
    case 'dad':
      setState({...state, dadSubDistrict: value})
      break
    case 'mom':
      setState({...state, momSubDistrict: value})
      break
    case 'parent':
      setState({...state, parentSubDistrict: value})
      break
    default:
      setState(...state)
      break
  }
}

export const cutDistrict = (district, setState, state, who) => {
  let value = district
  // Remove the prefix 'จังหวัด' from the address
  if (value.startsWith('อำเภอ')) {
    value = value.substring(5); // Remove the first 6 characters
  }
  switch(who){
    case 'reg':
      setState({...state, regDistrict: value})
      break
    case 'dad':
      setState({...state, dadDistrict: value})
      break
    case 'mom':
      setState({...state, momDistrict: value})
      break
    case 'parent':
      setState({...state, parentDistrict: value})
      break
    default:
      setState(...state)
      break
  }
}

export const cutProvince = (province, setState, state, who) => {
  let value = province
  // Remove the prefix 'จังหวัด' from the address
  if (value.startsWith('จังหวัด')) {
    value = value.substring(7); // Remove the first 8 characters
  }
  switch(who){
    case 'reg':
      setState({...state, regProvince: value})
      break
    case 'dad':
      setState({...state, dadProvince: value})
      break
    case 'mom':
      setState({...state, momProvince: value})
      break
    case 'parent':
      setState({...state, parentProvince: value})
      break
    default:
      setState(...state)
      break
  }
}

// remove multiple space between name
export const formatName = (inputName, setState, state, who) => {
  let str = inputName.replace(/\s+/g, ' ')
  switch(who){
    case 'reg':
      setState({...state, regName: str})
      break
    case 'dad':
      setState({...state, dadName: str})
      break
    case 'mom':
      setState({...state, momName: str})
      break
    case 'parent':
      setState({...state, parentName: str})
      break
    default:
      setState(...state)
      break
  }
}

// format regNameonly
export const formatRegNameOnly = (regName, setRegName) => {
  let str = regName.replace(/\s+/g, ' ')
  setRegName(str)
}

// format date
