const LoadingEffect = () => {
  return (
    <>
      <div className="spinner-overlay"></div>
      <div className="spinner-container">
        <div className="spinner"></div>
      </div>
    </>
  )
}

export default LoadingEffect