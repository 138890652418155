import { useState } from 'react'
import { Link } from 'react-router-dom'
import { getUser } from '../services/authorize'

const SidebarComponent = () => {
  const [active, setActive] = useState(0)
  return(
    <div className='navbar-dark bg-dark p-2 py-2 ps-3 pe-3 mt-5 d-flex justify-content-between flex-column vh-100'>
      <div>
        <hr className='text-secondary mt-1'/>
        <ul className='nav nav-pills flex-column mt-2'>
          <li className={active === 1 ? 'active nav-item p-2' : 'nav-item p-2'}
            onClick={e => setActive(1)}>
            <Link to="/" className='text-white text-decoration-none p-2'>
              <i className='bi bi-speedometer2 me-2 fs-6'></i>
              <span className='fs-6'><strong>Dashboard</strong></span>
            </Link>
          </li>
          {getUser() && (
          <>
            <li className={active === 2 ? 'active nav-item p-2' : 'nav-item p-2'}
              onClick={e => setActive(2)}>
              <Link  to="/listMatthayom" className='text-white text-decoration-none p-2'>
                <i className='bi bi-file-text me-2 fs-6'></i>
                <span className='fs-6'><strong>รายชื่อผู้สมัครมัธยม</strong></span>
              </Link>
            </li>
            <li className={active === 3 ? 'active nav-item p-2' : 'nav-item p-2'}
              onClick={e => setActive(3)}>
              <Link  to="/listPrathom" className='text-white text-decoration-none p-2'>
                <i className='bi bi-file-text me-2 fs-6'></i>
                <span className='fs-6'><strong>รายชื่อผู้สมัครประถม</strong></span>
              </Link>
            </li>
            <hr className='text-secondary mt-1 mb-1'/>
            <li className={active === 4 ? 'active nav-item p-2' : 'nav-item p-2'}
              onClick={e => setActive(4)}>
              <Link to="/registerMatthayom" className='text-white text-decoration-none p-2'>
                <i className='bi bi-person-add me-2 fs-5'></i>
                <span className='fs-6'><strong>สมัครนักเรียนมัธยม</strong></span>
              </Link>
            </li>
            <li className={active === 5 ? 'active nav-item p-2' : 'nav-item p-2'}
              onClick={e => setActive(5)}>
              <Link to="/registerPrathom" className='text-white text-decoration-none p-2'>
                <i className='bi bi-person-add me-2 fs-5'></i>
                <span className='fs-6'><strong>สมัครนักเรียนประถม</strong></span>
              </Link>
            </li>
            <li className={active === 6 ? 'active nav-item p-2' : 'nav-item p-2'}
              onClick={e => setActive(6)}>
              <Link to="/registerOldPrathom" className='text-white text-decoration-none p-2'>
                <i className='bi bi-person-add me-2 fs-5'></i>
                <span className='fs-6'><strong>สมัคร นร. จบ ป.6 เดิม</strong></span>
              </Link>
            </li>
            <li className={active === 7 ? 'active nav-item p-2' : 'nav-item p-2'}
              onClick={e => setActive(7)}>
              <Link to="/registerNoDoc" className='text-white text-decoration-none p-2'>
                <i className='bi bi-person-add me-2 fs-5'></i>
                <span className='fs-6'><strong>กรณีเอกสารไม่ครบ</strong></span>
              </Link>
            </li>
            <hr className='text-secondary mt-1 mb-1'/>
            <li className={active === 8 ? 'active nav-item p-2' : 'nav-item p-2'}
              onClick={e => setActive(8)}>
              <Link to="/registrationReport" className='text-white text-decoration-none p-2'>
                <i className='bi bi-files me-2 fs-5'></i>
                <span className='fs-6'><strong>ทะเบียนนักเรียนใหม่</strong></span>
              </Link>
            </li>
            <li className={active === 9 ? 'active nav-item p-2' : 'nav-item p-2'}
              onClick={e => setActive(9)}>
              {/* <Link to="/studentInClass" className='text-white text-decoration-none p-2'>
                <i className='bi bi-files me-2 fs-5'></i>
                <span className='fs-6'><strong>รายชื่อแยกตามชั้น</strong></span>
              </Link> */}
              <Link to="/updateStudentInfo" className='text-white text-decoration-none p-2'>
                <i className='bi bi-pencil me-2 fs-5'></i>
                <span className='fs-6'><strong>แก้ไขข้อมูลนักเรียน</strong></span>
              </Link>
            </li>
            <li className={active === 10 ? 'active nav-item p-2' : 'nav-item p-2'}
              onClick={e => setActive(10)}>
              {/* <Link to="/studentInClass" className='text-white text-decoration-none p-2'>
                <i className='bi bi-files me-2 fs-5'></i>
                <span className='fs-6'><strong>รายชื่อแยกตามชั้น</strong></span>
              </Link> */}
              <Link to="/printDocument" className='text-white text-decoration-none p-2'>
                <i className='bi bi-printer me-2 fs-5'></i>
                <span className='fs-6'><strong>ปริ้นเอกสารนักเรียน</strong></span>
              </Link>
            </li>
            </>
          )}
          {/* <hr className='text-secondary mt-1 mb-1'/>
          <li className={active === 6 ? 'active nav-item p-2' : 'nav-item p-2'}
            onClick={e => setActive(6)}>
            <Link to="/" className='text-white text-decoration-none p-2'>
              <i className='bi bi-houses me-2 fs-5'></i>
              <span className='fs-6'><strong>จัดการหอพัก</strong></span>
            </Link>
          </li>
          <li className={active === 7 ? 'active nav-item p-2' : 'nav-item p-2'}
            onClick={e => setActive(7)}>
            <Link to="/" className='text-white text-decoration-none p-2'>
              <i className='bi bi-house-add me-2 fs-5'></i>
              <span className='fs-6'><strong>จัดนักเรียนเข้าหอพัก</strong></span>
            </Link>
          </li>
          <hr className='text-secondary mt-1 mb-1'/> */}
        </ul>
      </div>
    </div>
  )
  
}

export default SidebarComponent