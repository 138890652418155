import '../reports/printReport.css'
import { useRef, useState, useEffect } from 'react'
import { useReactToPrint } from 'react-to-print'
import { getToken } from '../services/authorize'
import axios from 'axios'
import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'

const StudentInClass = () => {
  
  const printRef = useRef()
  const printReport = useReactToPrint({
    content: () => printRef.current,
    documentTitle: 'รายชื่อนักเรียนใหม่-2567'
  })

  const [studentData, setStudentData] = useState('')

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API}/studentInClassReport`,
    {
      headers: {
          authorization: `Bearer ${getToken()}`
      }
    })
    .then(response => {
      setStudentData(response.data)
    })
    .catch(err => alert(err))
  }, [])

  const exportToExcel = () => {

  }

  return (
    <div className="pt-4 ps-4 pe-4 mt-5 mb-4">
      <div className="container-fluid">
        <div className="row box-content rounded">
          {/* header of main content */}
          <h4 className="text-dark fw-bold">
            <i className="bi bi-file-person me-2"></i>
            รายชื่อนักเรียนแยกตามชั้นเรียน
          </h4>
          <hr className="text-dark mt-1 mb-3" />
          {/* body of main content */}
          <div className="d-flex justify-content-end">
            <button className="btn btn-success mb-2 me-1" onClick={printReport}>
              <i className="bi bi-printer me-2"></i>
              ปริ้น
            </button>
            <button className="btn btn-primary mb-2 me-1" onClick={exportToExcel}>
              <i className="bi bi-filetype-xlsx me-2"></i>
              บันทึกเป็น Excel
            </button>
          </div>
          <div ref={printRef} className="print-report pb-1 ps-3 pe-3">
            <div className="table-responsive">
              <table className="table table-bordered table-sm">
                <thead>

                </thead>
                <tbody>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StudentInClass