import axios from 'axios';
import { useEffect, useState } from 'react'
import { PieChart, Pie, Legend, Cell, Tooltip, ResponsiveContainer } from 'recharts'

const StudentEachProvice = () => {
  
  const [data, setData] = useState([])
    
   const fetchData = () => {
      axios.get(`${process.env.REACT_APP_API}/charts/studentEachProvince`)
      .then(respon => {
        setData(respon.data)
      })
      .catch(error => {
        console.log(error)
      })
    }
    
    useEffect(() => {
      // Fetch data initially
      fetchData()
  
      // Refresh data every 10 seconds
      const interval = setInterval(() => {
          fetchData()
      }, 30000)
  
      // Clear interval on component unmount
      return () => clearInterval(interval);
    }, []); // Empty dependency array to run effect only once

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#808080']

  const RADIAN = Math.PI / 180
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    )
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)', padding: '8px', borderRadius: '4px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
          {payload[0].name + ' : ' + payload[0].value}
        </div>   
      ) 
    }
  
    return null;
  }

   return (
     <div className="col-md-6 border-top border-secondary border-3 p-2 mt-2" style={{height: '320'}}>
       <ResponsiveContainer width="100%" height="100%">
          <PieChart width={400} height={400}>
          <text x="200" y="20" textAnchor="middle" dominantBaseline="middle">
           ผู้สมัครจากแต่ละจังหวัด
          </text>
            <Pie
             data={data}
             nameKey="province_group"
             cx="50%"
             cy="50%"
             labelLine={false}
             label={renderCustomizedLabel}
             outerRadius={80}
             fill="#8884d8"
             dataKey="studentCount"
           >
             {data.map((entry, index) => (
               <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
             ))}
          </Pie>
          <Legend formatter={(value, entry, index) => {
            return value
          }} />
          <Tooltip content={CustomTooltip} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  )
}
export default StudentEachProvice