import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { getUser, authenticate, logout } from '../services/authorize';
import axios from 'axios'
import Swal from 'sweetalert2'

const NavbarComponent = ({Toggle}) => {
  //const navigate = useNavigate()
  const [togglerClick, setTogglerClick] = useState(false)
  
  const toggleState = () => {
    setTogglerClick(!togglerClick)
  }

  useEffect(() => {
    
    const handleSize = () => {
      if(window.innerWidth > 768) {
        setTogglerClick(false)
      }else{
        setTogglerClick(true)
      }  
    }
    window.addEventListener('resize', handleSize)
    return () => {
      window.removeEventListener('resize', handleSize)
    }
  }, [])


  const [name, setName] = useState('')
  useEffect(() => {
    if (sessionStorage.getItem('user')) {
      // Parse the 'user' object from JSON
      const user = JSON.parse(sessionStorage.getItem('user'));
      setName(user.t_name)
      // console.log(user)
    }
  }, [])

  function handleClick(){
    toggleState()
    Toggle()
  }

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const handleUsername = (e) => {
    setUsername(e.target.value);
  }

  const handlePassword = (e) => {
    setPassword(e.target.value);
  }


  const loginSubmit = (e) => {
    e.preventDefault()
    axios.post(`${process.env.REACT_APP_API}/login`, {username, password})
    .then(response => {
      if(response.data.error){
        Swal.fire({
          title: "error",
          text: response.data.error,
          icon: "error"
        });
      }else{
        //Login success
        authenticate(response, () => {
          // Reload the page after successful login
          window.location.reload()
        })
      }
    }).catch(err => {
      Swal.fire({
        title: "error",
        text: err.response.data.error,
        icon: "error"
      })
    })
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top ps-2">
      <div className="container-fluid">
        <Link className="navbar-brand d-none d-md-block" to="/">WTI REGISTER</Link>
        <Link className="navbar-brand d-block d-md-none" onClick={handleClick}>
          <i className={togglerClick ? 'navbar-toggler-icon' : 'bi bi-x-lg'}></i>
        </Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          {getUser() ? (
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item mx-1 ps-1 rounded border">
              <Link className="nav-link text-white" to="/">
                <i className='bi bi-person-circle me-2'></i>
                <span><strong>{name}</strong></span>
              </Link>
            </li>
            <li className="nav-item mx-1 ps-1 rounded border bg-danger">
              <button className="nav-link text-white" onClick={()=>logout(()=> window.location.reload())}>
                <i className='bi bi-box-arrow-left me-2'></i>
                <span><strong>ออกจากระบบ</strong></span>
              </button>
            </li>
          </ul>
          ) : (
          <form className='form-inline ms-auto' onSubmit={loginSubmit}>
            <ul className="navbar-nav mb-2 mb-lg-0">
              <li className="nav-item mx-1 ps-1">
                <input type='text' className='form-control me-1' value={username} onChange={handleUsername} placeholder='ชื่อผู้ใช้' required/>
              </li>
              <li className="nav-item mx-1 ps-1">
                <input type='password' className='form-control me-1' value={password} onChange={handlePassword} placeholder='รหัสผ่าน' required/>
              </li>
              <li className="nav-item mx-1 ps-1">
                <button type="submit" className="btn btn-primary border"><i className="bi bi-box-arrow-right me-2"></i>เข้าสู่ระบบ</button>
              </li>
            </ul>
          </form>
          )}
        </div>
      </div>
    </nav>
  );
}

export default NavbarComponent;