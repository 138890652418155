import axios from 'axios';
import { useEffect, useState } from 'react';
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
const  StudentInClass = () => {

  const [data, setData] = useState([])
  
 const fetchData = () => {
    axios.get(`${process.env.REACT_APP_API}/charts/studentInClass`)
    .then(respon => {
      setData(respon.data)
    })
    .catch(error => {
      console.log(error)
    })
  }

  useEffect(() => {
    // Fetch data initially
    fetchData()

    // Refresh data every 10 seconds
    const interval = setInterval(() => {
        fetchData()
    }, 20000)

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, []); // Empty dependency array to run effect only once

  return (
    <div className="col-md-6 border-top border-secondary border-3 p-2 mt-2" style={{height: '400px'}}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={data}
        >
          <text x="200" y="20" textAnchor="middle" dominantBaseline="middle">
            ผู้สมัครแต่ละชั้นเรียน
          </text>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="regClass" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="maleCount" fill="#8884d8" name="นักเรียนชาย" activeBar={<Rectangle fill="pink" stroke="blue" />} />
          <Bar dataKey="femaleCount" fill="#82ca9d" name="นักเรียนหญิง" activeBar={<Rectangle fill="gold" stroke="purple" />} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

export default StudentInClass