// EditMatthayomComponent.js

import { useState, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import Swal from 'sweetalert2'
import LoadingEffect from './LoadingEffect'
import { getToken } from '../services/authorize'
import { reAssignAddress, cutSubDistrict, cutProvince, cutDistrict, formatName } from '../function/stringUtils'

const UpdateStudentComponent = () => {
  // let { st_id } = useParams()

  const inputIdCard = useRef(null)
  const inputIdCardDad = useRef(null)
  const inputIdCardMom = useRef(null)
  const inputIdCardParent = useRef(null)

  const navigate = useNavigate()

  const [studentId, setStudentId] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState({})
  const [state, setState] = useState({
    // Personal information
    regStId: "",
    regStudentID: "",
    regIdCard: "",
    regName: "",
    regBirthdate: "",
    regHomeId: "",
    regAddress: "",
    regSubDistrict: "",
    regDistrict: "",
    regProvince: "",
    regZipcode: "",
    // Education History
    oldAcademicClass: "",
    oldAcademicSchool: "",
    oldAcademicSubDistrict: "",
    oldAcademicDistrict: "",
    oldAcademicProvince: "",
    oldIslamicClass: "",
    oldIslamicSchool: "",
    oldIslamicSubDistrict: "",
    oldIslamicDistrict: "",
    oldIslamicProvince: "",
    // Apply stduying program
    regisAcademicStudy: "",
    regisAcademicClass: "",
    regisAcademicProgram: "",
    // Perent informatin
    dadIdCard: "",
    dadName: "",
    dadBirthdate: "",
    dadAddress: "",
    dadSubDistrict: "",
    dadDistrict: "",
    dadProvince: "",
    dadZipcode: "",
    dadRace: "ไทย", 
    dadNationality: "ไทย",
    dadLife: "",
    dadJob: "",
    dadWorkPlace: "",
    dadIncome: "",
    dadPhone: "",
    momIdCard: "",
    momName: "",
    momBirthdate: "",
    momAddress: "",
    momSubDistrict: "",
    momDistrict: "",
    momProvince: "",
    momZipcode: "",
    momRace: "ไทย",
    momNationality: "ไทย",
    momLife: "",
    momJob: "",
    momWorkPlace: "",
    momIncome: "",
    momPhone: "",
    parentStatus: "",
    allSibling: "",
    regChildOrder: "",
    studyingSibling: "",
    parent: "",
    parentIdCard: "",
    parentName: "",
    parentBirthdate: "",
    parentRelation: "",
    parentAddress: "",
    parentSubDistrict: "",
    parentDistrict: "",
    parentProvince: "",
    parentZipcode: "",
    parentJob: "",
    parentWorkPlace: "",
    parentIncome: "",
    parentPhone: "",
    regRace: "ไทย",
    regNationality: "ไทย",
    regReligion: "อิสลาม",
    regBlood: "",
    regSyndrome: "",
    regDrug: "",
    regFood: "",
    emerFName: "",
    emerLName: "",
    emerPhone: "",
    regisDate: ""
  })

  // destructuring
  const {
    // Personal information
    regStId,
    regStudentID,
    regIdCard,
    regName,
    regBirthdate,
    regHomeId,
    regAddress,
    regSubDistrict,
    regDistrict,
    regProvince,
    regZipcode,
    // Education History
    oldAcademicClass,
    oldAcademicSchool,
    oldAcademicSubDistrict,
    oldAcademicDistrict,
    oldAcademicProvince,
    oldIslamicClass,
    oldIslamicSchool,
    oldIslamicSubDistrict,
    oldIslamicDistrict,
    oldIslamicProvince,
    // Apply stduying program
    regisAcademicStudy,
    regisAcademicClass,
    regisAcademicProgram,
    // Perent informatin
    dadIdCard,
    dadName,
    dadBirthdate,
    dadAddress,
    dadSubDistrict,
    dadDistrict,
    dadProvince,
    dadZipcode,
    dadRace, 
    dadNationality,
    dadLife,
    dadJob,
    dadWorkPlace,
    dadIncome,
    dadPhone,
    momIdCard,
    momName,
    momBirthdate,
    momAddress,
    momSubDistrict,
    momDistrict,
    momProvince,
    momZipcode,
    momRace,
    momNationality,
    momLife,
    momJob,
    momWorkPlace,
    momIncome,
    momPhone,
    parentStatus,
    allSibling,
    regChildOrder,
    studyingSibling,
    parent,
    parentIdCard,
    parentName,
    parentBirthdate,
    parentRelation,
    parentAddress,
    parentSubDistrict,
    parentDistrict,
    parentProvince,
    parentZipcode,
    parentJob,
    parentWorkPlace,
    parentIncome,
    parentPhone,
    // extra value for Surrender document
    regRace,
    regNationality,
    regReligion,
    regBlood,
    regSyndrome,
    regDrug,
    regFood,
    emerFName,
    emerLName,
    emerPhone,
    regisDate
  } = state

  const handleInputChange = (e) => {
    setStudentId(e.target.value);
  }

  //set value to state
  const inputValue = name => event => {
    setState({...state, [name]:event.target.value})
    // setState({...state, regBirthdate: formatDate(event.target.value)})
  }

  // check user choose junior or high school
  const showClassToChoose = () => {
    if(regisAcademicStudy === 'high') {
      return (
        <>
          <option value="h4">ม. 4</option>
          <option value="h5">ม. 5</option>
          <option value="h6">ม. 6</option>
        </>
      )
    } else {
      return (
        <>
          <option value="h1">ม. 1</option>
          <option value="h2">ม. 2</option>
          <option value="h3">ม. 3</option>
        </>
      )
    }
  }

  // show program info text when use click on specific program study
  const programInfo = () => {
    if (regisAcademicProgram === 'smp') {
      return "วิทยาศาสตร์ คณิตศาสตร์"
    }else if (regisAcademicProgram === 'amp') {
      return "ศิลป์ คำนวณ ภาษาต่างประเทศ"
    }else if (regisAcademicProgram === 'asp') {
      return "ศิลป์ กีฬา ภาษาต่างประเทศ"
    }else if (regisAcademicProgram === 'simp') {
      return "ความเป็นเลิศทางวิทยาศาสตร์ คณิตศาสตร์ ภาษาต่างประเทศ"
    }
  }

  // check if radio otherParent is checked or no
  const [isOtherParentDisabled, setIsOtherParentDisabled] = useState(true)
  const [isRegisAcademic, setIsRegisAcademic] = useState(true)
  
  const searchStudent = () => {
    axios.get(`${process.env.REACT_APP_API}/listRegister/updateByIdNumber/${studentId}`,
    {
      headers: {
          authorization: `Bearer ${getToken()}`
      }
    })
    .then(response => {
      if (response.data[0].st_idnumber) {
        setData(response.data[0])
      } else {
        Swal.fire({
          title: 'ไม่มีข้อมูล',
          text: 'ไม่พบข้อมูลจากเลขประจำตัวที่ระบุ',
          icon: "error"
        })
      }
      setIsLoading(false)
    })
    .catch(err => {
      // console.log(err)
      Swal.fire({
        title: 'ERROR',
        text: err.message,
        icon: "error"
      })
    })

  }

  useEffect(() => {
    parent === 'other' ? setIsOtherParentDisabled(false) : setIsOtherParentDisabled(true)
    regisAcademicStudy !== "" ? setIsRegisAcademic(false) : setIsRegisAcademic(true)
  }, [parent, regisAcademicStudy])

  useEffect(() => {
    setState({
      regStId: data?.st_id ?? '',
      regStudentID: data?.st_idnumber ?? '',
      regIdCard: data?.st_idcard ?? '',
      regName: data?.st_name ?? '',
      regBirthdate: data?.st_birthdate ?? '',
      regHomeId: data?.st_homeID ?? '',
      regAddress: data?.st_current ?? '',
      regSubDistrict: data?.st_currentSubDistrict ?? '',
      regDistrict: data?.st_currentDistrict ?? '',
      regProvince: data?.st_currentProvince ?? '',
      regZipcode: data?.st_zipcode ?? '',
      oldAcademicClass: data?.st_prevAcademicClass ?? '',
      oldAcademicSchool: data?.st_prevAcademicSchool ?? '',
      oldAcademicSubDistrict: data?.st_prevAcademicSubDistrict ?? '',
      oldAcademicDistrict: data?.st_prevAcademicDistrict ?? '',
      oldAcademicProvince: data?.st_prevAcademicProvince ?? '',
      oldIslamicClass: data?.st_prevIslamicClass ?? '',
      oldIslamicSchool: data?.st_prevIslamicSchool ?? '',
      oldIslamicSubDistrict: data?.st_prevIslamicSubDistrict ?? '',
      oldIslamicDistrict: data?.st_prevIslamicDistrict ?? '',
      oldIslamicProvince: data?.st_prevIslamicProvince ?? '',
      regisAcademicStudy: data?.regis_academicStudy ?? '',
      regisAcademicClass: data?.regis_academicClass ?? '',
      regisAcademicProgram: data?.regis_academicProgram ?? '',
      dadIdCard: data?.st_fatherIdCard ?? '',
      dadName: data?.st_fatherName ?? '',
      dadBirthdate: data?.st_fatherBirthdate ?? '',
      dadAddress: data?.st_fatherAddress ?? '',
      dadSubDistrict: data?.st_fatherSubDistrict ?? '',
      dadDistrict: data?.st_fatherDistrict ?? '',
      dadProvince: data?.st_fatherProvince ?? '',
      dadZipcode: data?.st_fatherZipcode ?? '',
      dadRace: data?.st_fatherRace ?? '',
      dadNationality: data?.st_fatherNationality ?? '',
      dadLife: data?.st_fatherLife ?? '',
      dadJob: data?.st_fatherJob ?? '',
      dadWorkPlace: data?.st_fatherWorkPlace ?? '',
      dadIncome: data?.st_fatherAnnualIncome ?? '',
      dadPhone: data?.st_fatherPhone ?? '',
      momIdCard: data?.st_motherIdCard ?? '',
      momName: data?.st_motherName ?? '',
      momBirthdate: data?.st_motherBirthdate ?? '',
      momAddress: data?.st_motherAddress ?? '',
      momSubDistrict: data?.st_motherSubDistrict ?? '',
      momDistrict: data?.st_motherDistrict ?? '',
      momProvince: data?.st_motherProvince ?? '',
      momZipcode: data?.st_motherZipcode ?? '',
      momRace: data?.st_motherRace ?? '',
      momNationality: data?.st_motherNationality ?? '',
      momLife: data?.st_motherLife ?? '',
      momJob: data?.st_motherJob ?? '',
      momWorkPlace: data?.st_motherWorkPlace ?? '',
      momIncome: data?.st_motherAnnualIncome ?? '',
      momPhone: data?.st_motherPhone ?? '',
      parentStatus: data?.st_parentStatus ?? '',
      allSibling: data?.st_sibling ?? '',
      regChildOrder: data?.st_childnumber ?? '',
      studyingSibling: data?.st_studyingSibling ?? '',
      parent: data?.st_parent ?? '',
      parentIdCard: data?.st_parentIdCard ?? '',
      parentName: data?.st_parentname ?? '',
      parentBirthdate: data?.st_parentBirthdate ?? '',
      parentRelation: data?.st_parentRelation ?? '',
      parentAddress: data?.st_parentAddress ?? '',
      parentSubDistrict: data?.st_parentSubDistrict ?? '',
      parentDistrict: data?.st_parentDistrict ?? '',
      parentProvince: data?.st_parentProvince ?? '',
      parentZipcode: data?.st_parentZipcode ?? '',
      parentJob: data?.st_parentjob ?? '',
      parentWorkPlace: data?.st_parentWorkPlace ?? '',
      parentIncome: data?.st_parentannualincome ?? '',
      parentPhone: data?.st_parentPhone ?? '',
      regRace: data?.st_race ?? '',
      regNationality: data?.st_nationality ?? '',
      regReligion: data?.st_religion ?? '',
      regBlood: data?.st_blood ?? '',
      regSyndrome: data?.st_syndrome ?? '',
      regDrug: data?.st_drug ?? '',
      regFood: data?.st_food ?? '',
      emerFName: data?.st_emergencyFirstName ?? '',
      emerLName: data?.st_emergencyLastName ?? '',
      emerPhone: data?.st_emergencyPhone ?? '',
      regisDate: data?.regis_date

    })
  }, [data])

  // สง่ข้อมูลไปที่ api
  const submitForm = (e) => {
    e.preventDefault();
    axios.post(`${process.env.REACT_APP_API}/studentInfo/update/${regStId}`,
      {
        regStId,
        regStudentID,
        regIdCard,
        regName,
        regBirthdate,
        regHomeId,
        regAddress,
        regSubDistrict,
        regDistrict,
        regProvince,
        regZipcode,
        oldAcademicClass,
        oldAcademicSchool,
        oldAcademicSubDistrict,
        oldAcademicDistrict,
        oldAcademicProvince,
        oldIslamicClass,
        oldIslamicSchool,
        oldIslamicSubDistrict,
        oldIslamicDistrict,
        oldIslamicProvince,
        regisAcademicStudy,
        regisAcademicClass,
        regisAcademicProgram,
        dadIdCard,
        dadName,
        dadBirthdate,
        dadAddress,
        dadSubDistrict,
        dadDistrict,
        dadProvince,
        dadZipcode,
        dadRace,
        dadNationality,
        dadLife,
        dadJob,
        dadWorkPlace,
        dadIncome,
        dadPhone,
        momIdCard,
        momName,
        momBirthdate,
        momAddress,
        momSubDistrict,
        momDistrict,
        momProvince,
        momZipcode,
        momRace,
        momNationality,
        momLife,
        momJob,
        momWorkPlace,
        momIncome,
        momPhone,
        parentStatus,
        allSibling,
        regChildOrder,
        studyingSibling,
        parent,
        parentIdCard,
        parentName,
        parentBirthdate,
        parentRelation,
        parentAddress,
        parentSubDistrict,
        parentDistrict,
        parentProvince,
        parentZipcode,
        parentJob,
        parentWorkPlace,
        parentIncome,
        parentPhone,
        regRace,
        regNationality,
        regReligion,
        regBlood,
        regSyndrome,
        regDrug,
        regFood,
        emerFName,
        emerLName,
        emerPhone,
        regisDate
      },
      {
        headers: {
            authorization: `Bearer ${getToken()}`
        }
      }).then(response => {
        Swal.fire({
          title: response.data.message,
          icon: "success",
        }).then(() => {
          navigate(`/printDocument/${regStudentID}`)
        })
        //console.log(response)
      }).catch(err => {
        Swal.fire({
          title: "error",
          text: err.response.data.message,
          icon: "error"
        })
        //console.log(err)
      })
  }

  return (
    <div className="pt-4 ps-4 pe-4 mt-5 mb-4">
        <div className="container-fluid">   
          <div className="row box-content rounded">
            {/* header of main content */}
            <h4 className="text-dark fw-bold d-flex align-items-center">
              <i className='bi bi-person-add me-2'></i>
              แก้ไขข้อมูลนักเรียน
              <div className="d-flex ms-2 align-items-center">
                <input type="text" className="form-control" id="studentId" value={studentId} onChange={handleInputChange} placeholder="เลขประจำตัวนักเรียน" style={{ width: '200px' }}/>
                <button className="btn btn-primary mb-2 ms-2" onClick={searchStudent}> ค้นหา</button>
              </div>
            </h4>
            <hr className='text-dark mt-1 mb-3'/>
            {/* body of main content */}
            {isLoading && <LoadingEffect />}
            <form onSubmit={submitForm}>
              <h6 className="text-light bg-secondary fw-bold p-2">- ข้อมูลผู้สมัคร</h6>
              <hr className='text-secondary mt-1 mb-3'/>
              <div className="row mb-3 ps-1 pe-1">
                <label htmlFor="inputIdCard" className="col-sm-2 col-form-label">เลขประจำตัวนักเรียน</label>
                <div className="col-sm-4 p-2">
                  <input type="text" className="form-control" id="inputStId" value={data.st_idnumber} disabled/>
                </div>
                <label htmlFor="inputBirthdate" className="col-sm-2 col-form-label">วัน เดือน ปี สมัคร</label>
                <div className="col-sm-4 p-2">
                  <input type="text" className="form-control" id="inputRegisDate" value={regisDate} onChange={inputValue("regisDate")} placeholder="เช่น 7/02/2567" required/>
                </div>
              </div>
              <div className="row mb-3 ps-1 pe-1">
                <label htmlFor="inputIdCard" className="col-sm-2 col-form-label">เลขประจำตัวประชาชน</label>
                <div className="col-sm-4 p-2">
                  <input type="text" className="form-control" ref={inputIdCard} id="inputIdCard" value={regIdCard} onChange={inputValue("regIdCard")} placeholder="รหัสประจำตัวประชาชน" required/>
                </div>
              </div>
              <div className="row mb-3 ps-1 pe-1">
                <label htmlFor="inputName" className="col-sm-2 col-form-label">ชื่อ-สกุล</label>
                <div className="col-sm-4 p-2">
                  <input type="text" className="form-control" id="inputName" value={regName} onChange={inputValue("regName")} onBlur={(e) => formatName(e.target.value, setState, state, 'reg')} placeholder="ชื่อ นามสกุลพร้อมคำนำหน้า" required/>
                </div>
                <label htmlFor="inputBirthdate" className="col-sm-2 col-form-label">วัน เดือน ปีเกิด</label>
                <div className="col-sm-4 p-2">
                  <input type="text" className="form-control" id="inputBirthdate" value={regBirthdate} onChange={inputValue("regBirthdate")} placeholder="เช่น 31/11/2556" required/>
                </div>
              </div>
              <div className="row mb-3 ps-1 pe-1">
                <label htmlFor="inputRegisAddress" className="col-sm-2 col-form-label">ที่อยู่</label>
                <div className="col-sm-4 p-2">
                  <textarea type="text" className="form-control" id="inputRegisAddress" rows="3" value={regAddress} onChange={inputValue("regAddress")} onBlur={(e) => reAssignAddress(e.target.value, setState, state, 'reg')} placeholder="กรอกบ้านเลขที่ ถนน ซอย หมู่"></textarea>
                </div>
                <label htmlFor="inputSubDistrict" className="col-sm-2 col-form-label">ตำบล/แขวง</label>
                <div className="col-sm-4 p-2">
                  <input type="text" className="form-control" id="inputSubDistrict" value={regSubDistrict} onChange={inputValue("regSubDistrict")} onBlur={(e) => cutSubDistrict(e.target.value, setState, state, 'reg')} placeholder="ระบุตำบล หรือแขวง"/>
                </div>
              </div>
              <div className="row mb-3 ps-1 pe-1">
                <label htmlFor="inputDistrict" className="col-sm-2 col-form-label">อำเภอ/เขต</label>
                <div className="col-sm-4 p-2">
                  <input type="text" className="form-control" id="inputDistrict" value={regDistrict} onChange={inputValue("regDistrict")} onBlur={(e) => cutDistrict(e.target.value, setState, state, 'reg')} placeholder="ระบุ อำเภอ หรือเขต"/>
                </div>
                <label htmlFor="inputProvince" className="col-sm-2 col-form-label">จังหวัด</label>
                <div className="col-sm-4 p-2">
                  <input type="text" className="form-control" id="inputProvince" value={regProvince} onChange={inputValue("regProvince")} onBlur={(e) => cutProvince(e.target.value, setState, state, 'reg')}placeholder="ระบุ จังหวัด"/>
                </div>
              </div>
              <div className="row mb-3 ps-1 pe-1">
                <label htmlFor="inputZipcode" className="bg-light col-sm-2 col-form-label">รหัสไปรษณีย์</label>
                <div className="col-sm-4 bg-light p-2">
                  <input type="text" className="form-control" id="inputZipcode" value={regZipcode} onChange={inputValue("regZipcode")} placeholder="กรอกรหัสไปรษณีย์"/>
                </div>
                <label htmlFor="inputIdHome" className="bg-light col-sm-2 col-form-label">เลขรหัสประจำบ้าน</label>
                <div className="bg-light col-sm-4 p-2">
                  <input type="text" className="form-control" id="inputIdHome" value={regHomeId} onChange={inputValue("regHomeId")} placeholder="เลขรหัสประจำบ้าน"/>
                </div>
              </div>
              <div className='row mb-3 ps-1 pe-1'>
                <label htmlFor='inputRace' className='col-sm-2 col-form-label'>เชื้อชาติ</label>
                <div className='col-sm-4 p-2'>
                  <input type='text' className='form-control' id='inputRace' value={regRace} onChange={inputValue("regRace")} placeholder="ระบุเชื้อชาติ" required/>
                </div>
                <label htmlFor='inputNationality' className='col-sm-2 col-form-label'>สัญชาติ</label>
                <div className='col-sm-4 p-2'>
                  <input type='text' className='form-control' id='inputNationality' value={regNationality} onChange={inputValue("regNationality")} placeholder="ระบุสัญชาติ" required/>
                </div>
              </div>
              <div className='row mb-3 ps-1 pe-1'>
                <label htmlFor='inputReligion' className='col-sm-2 col-form-label'>ศาสนา</label>
                <div className='col-sm-4 p-2'>
                  <input type='text' className='form-control' id='inputReligion' value={regReligion} onChange={inputValue("regReligion")} placeholder="ระบุศาสนา" required/>
                </div>
                <label htmlFor='inputBlood' className='col-sm-2 col-form-label'>หมู่เลือด</label>
                <div className='col-sm-4 p-2'>
                  <input type='text' className='form-control' id='inputBlood' value={regBlood} onChange={inputValue("regBlood")} placeholder="ระบุหมู่เลือด ถ้าทราบ"/>
                </div>
              </div>
              <div className='row mb-3 ps-1 pe-1'>
                <label htmlFor='inputSyndrom' className='col-sm-2 col-form-label'>โรคประจำตัว</label>
                <div className='col-sm-4 p-2'>
                  <input type='text' className='form-control' id='inputSyndrom' value={regSyndrome} onChange={inputValue("regSyndrome")} placeholder="โรคประจำตัว ถ้ามี"/>
                </div>
                <label htmlFor='inputDrug' className='col-sm-2 col-form-label'>แพ้ยา</label>
                <div className='col-sm-4 p-2'>
                  <input type='text' className='form-control' id='inputDrug' value={regDrug} onChange={inputValue("regDrug")} placeholder="การแพ้ยา ถ้ามี"/>
                </div>
              </div>
              <hr className='text-dark mt-1 mb-3'/>
              <h6 className="text-light bg-secondary fw-bold p-2">- ประวัติการศึกษา</h6>
              <hr className='text-secondary mt-1 mb-3'/>
              <div className="row mb-3 ps-1 pe-1">
                <label htmlFor="inputFinishSaman" className="bg-light col-sm-2 col-form-label">จบสามัญสูงสุด ชั้น</label>
                <div className="bg-light col-sm-4 p-2">
                  <select className="form-control" value={oldAcademicClass} onChange={inputValue("oldAcademicClass")} id="inputFinishSaman" required>
                    <option value="">-เลือกชั้นสามัญที่จบมา-</option>
                    <option value="p6">ป. 6</option>
                    <option value="h1">ม. 1</option>
                    <option value="h2">ม. 2</option>
                    <option value="h3">ม. 3</option>
                    <option value="h4">ม. 4</option>
                    <option value="h5">ม. 5</option>
                    <option value="h6">ม. 6</option>
                  </select>
                </div>
                <label htmlFor="inputOldSchool" className="bg-light col-sm-2 col-form-label">จากโรงเรียน</label>
                <div className="bg-light col-sm-4 p-2">
                  <input type="text" className="form-control" value={oldAcademicSchool} onChange={inputValue("oldAcademicSchool")} id="inputOldSchool" placeholder="กรอกชื่อโรงเรียนเดิม" required/>
                </div>
              </div>
              <div className="row mb-3 ps-1 pe-1">
                <label htmlFor="inputOldAcademicSubdistrict" className="bg-light col-sm-2 col-form-label">ตำบล</label>
                <div className="bg-light col-sm-4 p-2">
                  <input type="text" className="form-control" value={oldAcademicSubDistrict} onChange={inputValue("oldAcademicSubDistrict")} id="inputOldAcademicSubdistrict" placeholder="ระบุตำบลของโรงเรียนเดิม" required/>
                </div>
                <label htmlFor="inputOldSchoolDistrict" className="bg-light col-sm-2 col-form-label">อำเภอ</label>
                <div className="bg-light col-sm-4 p-2">
                  <input type="text" className="form-control" value={oldAcademicDistrict} onChange={inputValue("oldAcademicDistrict")} id="inputOldSchoolDistrict" placeholder="ระบุอำเภอของโรงเรียนเดิม" required/>
                </div>
              </div>
              <div className="row mb-3 ps-1 pe-1">
                <label htmlFor="inputOldSchoolProvince" className="bg-light col-sm-2 col-form-label">จังหวัด</label>
                <div className="bg-light col-sm-4 p-2">
                  <input type="text" className="form-control" value={oldAcademicProvince} onChange={inputValue("oldAcademicProvince")} id="inputOldSchoolProvince" placeholder="ระบุจังหวัดของโรงเรียนเดิม" required/>
                </div>
              </div>
              <hr className='text-dark mt-1 mb-3'/>
              <div className="row mb-3 ps-1 pe-1">
                <label htmlFor="inputFinishIslam" className="bg-light col-sm-2 col-form-label">จบศาสนาชั้นสูงสุด ชั้น</label>
                <div className="bg-light col-sm-4 p-2">
                  <select className="form-control" value={oldIslamicClass} onChange={inputValue("oldIslamicClass")} id="inputFinishIslam" required>
                    <option value="">-เลือกชั้นศาสนาที่จบมา-</option>
                    <option value="0">ตาดีกา</option>
                    <option value="1">ชั้น 1</option>
                    <option value="2">ชั้น 2</option>
                    <option value="3">ชั้น 3</option>
                    <option value="4">ชั้น 4</option>
                    <option value="5">ชั้น 5</option>
                    <option value="6">ชั้น 6</option>
                    <option value="7">ชั้น 7</option>
                    <option value="8">ชั้น 8</option>
                    <option value="9">ชั้น 9</option>
                    <option value="10">ชั้น 10</option>
                  </select>
                </div>
                <label htmlFor="inputOldIslamSchool" className="bg-light col-sm-2 col-form-label">จากโรงเรียน</label>
                <div className="bg-light col-sm-4 p-2">
                  <input type="text" className="form-control" value={oldIslamicSchool} onChange={inputValue("oldIslamicSchool")} id="inputOldIslamSchool" placeholder="กรอกชื่อโรงเรียนเดิม" required/>
                </div>
              </div>
              <div className="row mb-3 ps-1 pe-1">
                <label htmlFor="inputIslamicSubdistrict" className="bg-light col-sm-2 col-form-label">ตำบล</label>
                <div className="bg-light col-sm-4 p-2">
                  <input type="text" className="form-control" value={oldIslamicSubDistrict} onChange={inputValue("oldIslamicSubDistrict")} id="inputIslamicSubdistrict" placeholder="ระบุตำบลของโรงเรียนเดิม" required/>
                </div>
                <label htmlFor="inputFinishIslamDistrict" className="bg-light col-sm-2 col-form-label">อำเภอ</label>
                <div className="bg-light col-sm-4 p-2">
                  <input type="text" className="form-control" value={oldIslamicDistrict} onChange={inputValue("oldIslamicDistrict")} id="inputFinishIslamDistrict" placeholder="ระบุอำเภอของโรงเรียนเดิม" required/>
                </div>
              </div>
              <div className="row mb-3 ps-1 pe-1">
                <label htmlFor="inputFinishIslamProvince" className="bg-light col-sm-2 col-form-label">จังหวัด</label>
                <div className="bg-light col-sm-4 p-2">
                  <input type="text" className="form-control" value={oldIslamicProvince} onChange={inputValue("oldIslamicProvince")} id="inputFinishIslamProvince" placeholder="ระบุจังหวัดของโรงเรียนเดิม" required/>
                </div>
              </div>
              <hr className='text-secondary mt-1 mb-3'/>
              <h6 className="text-light bg-secondary fw-bold p-2">- โปรแกรมที่สมัครเรียน</h6>
              <hr className='text-secondary mt-1 mb-3'/>
              <div className="row mb-3 ps-1 pe-1">
                <label className="bg-light col-sm-2 col-form-label">สมัครเรียนระดับ</label>
                <div className="btn-group col-sm-4 p-2" role="group">
                  <input type="radio" className="btn-check" name="chooseClass" id="chooseClass1" value="junior" checked={regisAcademicStudy === 'junior'} onChange={inputValue("regisAcademicStudy")} autoComplete="off" required/>
                  <label className="btn btn-outline-primary" htmlFor="chooseClass1">ม. ต้น</label>
                  <input type="radio" className="btn-check" name="chooseClass" id="chooseClass2" value="high" checked={regisAcademicStudy === 'high'} onChange={inputValue("regisAcademicStudy")} autoComplete="off"/>
                  <label className="btn btn-outline-primary" htmlFor="chooseClass2">ม. ปลาย</label>
                </div>
                <label htmlFor="inputChooseRoom" className="bg-light col-sm-2 col-form-label">ชั้น</label>
                <div className="bg-light col-sm-4 p-2">
                  <select className="form-control" value={regisAcademicClass} onChange={inputValue("regisAcademicClass")} id="inputChooseRoom" required disabled={isRegisAcademic}>
                    <option value="">-เลือกชั้นเรียน-</option>
                    {showClassToChoose()}
                  </select>
                </div>
              </div>
              <div className="row mb-3 ps-1 pe-1">
                <label className="bg-light col-sm-2 col-form-label">โปรแกรม</label>
                <div className="btn-group col-sm-4 p-2 bg-light" role="group">
                  <input type="radio" className="btn-check" name="program" id="programSMP" value="smp" checked={regisAcademicProgram === 'smp'} onChange={inputValue("regisAcademicProgram")} autoComplete="off" required/>
                  <label className="btn btn-outline-primary" htmlFor="programSMP">SMP</label>
                  <input type="radio" className="btn-check" name="program" id="programAMP" value="amp" checked={regisAcademicProgram === 'amp'} onChange={inputValue("regisAcademicProgram")} autoComplete="off"/>
                  <label className="btn btn-outline-primary" htmlFor="programAMP">AMP</label>
                  <input type="radio" className="btn-check" name="program" id="programASP" value="asp" checked={regisAcademicProgram === 'asp'} onChange={inputValue("regisAcademicProgram")} autoComplete="off" required/>
                  <label className="btn btn-outline-primary" htmlFor="programASP">ASP</label>
                  <input type="radio" className="btn-check" name="program" id="programSIMP" value="simp" checked={regisAcademicProgram === 'simp'} onChange={inputValue("regisAcademicProgram")} autoComplete="off"/>
                  <label className="btn btn-outline-primary" htmlFor="programSIMP">SIMP</label>
                  <input type="radio" className="btn-check" name="program" id="programNo" value="" checked={regisAcademicProgram === ''} onChange={inputValue("regisAcademicProgram")} autoComplete="off"/>
                  <label className="btn btn-outline-danger" htmlFor="programNo">ไม่เลือก</label>
                </div>
                <label className="bg-light col-sm-6 col-form-label">{programInfo()}</label>
              </div>
              <h6 className="text-light bg-secondary fw-bold p-2">- ข้อมูลผู้ปกครอง</h6>
              <hr className='text-secondary mt-1 mb-3'/>
              {/* Dad informaion */}
              <div className="row mb-3 ps-1 pe-1">
                <label htmlFor="inputIdCardDad" className="col-sm-2 col-form-label">เลขประจำตัวประชาชน บิดา</label>
                <div className="col-sm-4 p-2">
                  <input type="text" className="form-control" id="inputIdCardDad" ref={inputIdCardDad} value={dadIdCard} onChange={inputValue("dadIdCard")} placeholder="รหัสประจำตัวประชาชน บิดา" required/>
                </div>
              </div>
              <div className="row mb-3 ps-1 pe-1">
                <label htmlFor="inputDadName" className="col-sm-2 col-form-label">บิดา ชื่อ</label>
                <div className="col-sm-4 p-2">
                  <input type="text" className="form-control" id="inputDadName" value={dadName} onChange={inputValue("dadName")} onBlur={(e) => formatName(e.target.value, setState, state, 'dad')} placeholder="ชื่อเต็ม บิดา" required/>
                </div>
                <label htmlFor="inputDadBirthdate" className="col-sm-2 col-form-label">วัน เดือน ปีเกิด บิดา</label>
                <div className="col-sm-4 p-2">
                  <input type="text" className="form-control" id="inputDadBirthdate" value={dadBirthdate} onChange={inputValue("dadBirthdate")} placeholder="เช่น 31/11/2556" required/>
                </div>
              </div>
              <div className="row mb-3 ps-1 pe-1">
                <label htmlFor="inputDadAddress" className="col-sm-2 col-form-label">ที่อยู่</label>
                <div className="col-sm-4 p-2">
                  <textarea type="text" className="form-control" id="inputDadAddress" rows="3" value={dadAddress} onChange={inputValue("dadAddress")} onBlur={(e) => reAssignAddress(e.target.value, setState, state, 'dad')} placeholder="กรอกบ้านเลขที่ ถนน ซอย หมู่"></textarea>
                </div>
                <label htmlFor="inputDadSubDistrict" className="col-sm-2 col-form-label">ตำบล/แขวง</label>
                <div className="col-sm-4 p-2">
                  <input type="text" className="form-control" id="inputDadSubDistrict" value={dadSubDistrict} onChange={inputValue("dadSubDistrict")} onBlur={(e) => cutSubDistrict(e.target.value, setState, state, 'dad')} placeholder="ระบุตำบล หรือแขวง"/>
                </div>
              </div>
              <div className="row mb-3 ps-1 pe-1">
                <label htmlFor="inputDadDistrict" className="col-sm-2 col-form-label">อำเภอ/เขต</label>
                <div className="col-sm-4 p-2">
                  <input type="text" className="form-control" id="inputDadDistrict" value={dadDistrict} onChange={inputValue("dadDistrict")} onBlur={(e) => cutDistrict(e.target.value, setState, state, 'dad')} placeholder="ระบุ อำเภอ หรือเขต"/>
                </div>
                <label htmlFor="inputDadProvince" className="col-sm-2 col-form-label">จังหวัด</label>
                <div className="col-sm-4 p-2">
                  <input type="text" className="form-control" id="inputDadProvince" value={dadProvince} onChange={inputValue("dadProvince")} onBlur={(e) => cutProvince(e.target.value, setState, state, 'dad')} placeholder="ระบุ จังหวัด"/>
                </div>
              </div>
              <div className="row mb-3 ps-1 pe-1">
                <label htmlFor="inputDadZipcode" className="bg-light col-sm-2 col-form-label">รหัสไปรษณีย์</label>
                <div className="col-sm-4 bg-light p-2">
                  <input type="text" className="form-control" id="inputDadZipcode" value={dadZipcode} onChange={inputValue("dadZipcode")} placeholder="กรอกรหัสไปรษณีย์"/>
                </div>
              </div>
              <div className="row-mb-3 mb-3 ps-1 pe-1">
                <label className="col-sm-2 col-form-label">สถานภาพของบิดา</label>
                <div className="btn-group" role="group">
                  <input type="radio" className="btn-check" name="dad_life" id="dad_life1" value={'1'} checked={dadLife === '1'} onChange={inputValue("dadLife")} autoComplete="off" required/>
                  <label className="btn btn-outline-primary" htmlFor="dad_life1">ยังมีชีวิต</label>
                  <input type="radio" className="btn-check" name="dad_life" id="dad_life2" value={'0'} checked={dadLife === '0'} onChange={inputValue("dadLife")} autoComplete="off"/>
                  <label className="btn btn-outline-primary" htmlFor="dad_life2">เสียชีวิต</label>
                </div>
              </div>
              <div className='row mb-3 ps-1 pe-1'>
                <label htmlFor='inputDadRace' className='col-sm-2 col-form-label'>เชื้อชาติ</label>
                <div className='col-sm-4 p-2'>
                  <input type='text' className='form-control' id='inputDadRace' value={dadRace} onChange={inputValue("dadRace")} placeholder="ระบุเชื้อชาติของบิดา" required/>
                </div>
                <label htmlFor='inputDadNationality' className='col-sm-2 col-form-label'>สัญชาติ</label>
                <div className='col-sm-4 p-2'>
                  <input type='text' className='form-control' id='inputDadNationality' value={dadNationality} onChange={inputValue("dadNationality")} placeholder="ระบุสัญชาติบิดา" required/>
                </div>
              </div>
              <div className='row mb-3 ps-1 pe-1'>
                <label htmlFor='inputDadJob' className='bg-light col-sm-2 col-form-label'>อาชีพ</label>
                <div className='col-sm-4 p-2 bg-light'>
                  <input type='text' className='form-control' id='inputDadJob' value={dadJob} onChange={inputValue("dadJob")} placeholder="ระบุอาชีพของบิดา" required/>
                </div>
                <label htmlFor='inputDadWorkPlace' className='bg-light col-sm-2 col-form-label'>สถานที่ทำงาน</label>
                <div className='col-sm-4 p-2 bg-light'>
                  <input type='text' className='form-control' id='inputDadWorkPlace' value={dadWorkPlace} onChange={inputValue("dadWorkPlace")} placeholder="ระบุสถานที่ทำงานของบิดา" required/>
                </div>
              </div>
              <div className='row mb-3 ps-1 pe-1'>
                <label htmlFor='inputDadIncome' className='bg-light col-sm-2 col-form-label'>รายได้/เดือน (บาท)</label>
                <div className='col-sm-4 p-2 bg-light'>
                  <input type='text' className='form-control' id='inputDadIncome' value={dadIncome} onChange={inputValue("dadIncome")} placeholder="ระบุรายได้เช่น 15000" required/>
                </div>
                <label htmlFor='inputDadPhone' className='bg-light col-sm-2 col-form-label'>เบอร์โทรศัพท์</label>
                <div className='col-sm-4 p-2 bg-light'>
                  <input type='text' className='form-control' id='inputDadPhone' value={dadPhone} onChange={inputValue("dadPhone")} placeholder="ระบุเบอรโทรศัพท์ของบิดา" required/>
                </div>
              </div>
              {/* Mom information */}
              <hr className='text-secondary mt-1 mb-3'/>
              <div className="row mb-3 ps-1 pe-1">
                <label htmlFor="inputIdCardMom" className="col-sm-2 col-form-label">เลขประจำตัวประชาชน มารดา</label>
                <div className="col-sm-4 p-2">
                  <input type="text" className="form-control" id="inputIdCardMom" ref={inputIdCardMom} value={momIdCard} onChange={inputValue("momIdCard")} placeholder="รหัสประจำตัวประชาชน มาดา" required/>
                </div>
              </div>
              <div className="row mb-3 ps-1 pe-1">
                <label htmlFor="inputMomName" className="col-sm-2 col-form-label">มารดา ชื่อ</label>
                <div className="col-sm-4 p-2">
                  <input type="text" className="form-control" id="inputMomName" value={momName} onChange={inputValue("momName")} onBlur={(e) => formatName(e.target.value, setState, state, 'mom')} placeholder="ชื่อเต็ม มารดา" required/>
                </div>
                <label htmlFor="inputMomBirthdate" className="col-sm-2 col-form-label">วัน เดือน ปีเกิด มารดา</label>
                <div className="col-sm-4 p-2">
                  <input type="text" className="form-control" id="inputMomBirthdate" value={momBirthdate} onChange={inputValue("momBirthdate")} placeholder="เช่น 31/11/2556" required/>
                </div>
              </div>
              <div className="row mb-3 ps-1 pe-1">
                <label htmlFor="inputMomAddress" className="col-sm-2 col-form-label">ที่อยู่</label>
                <div className="col-sm-4 p-2">
                  <textarea type="text" className="form-control" id="inputMomAddress" rows="3" value={momAddress} onChange={inputValue("momAddress")} onBlur={(e) => reAssignAddress(e.target.value, setState, state, 'mom')} placeholder="กรอกบ้านเลขที่ ถนน ซอย หมู่"></textarea>
                </div>
                <label htmlFor="inputMomSubDistrict" className="col-sm-2 col-form-label">ตำบล/แขวง</label>
                <div className="col-sm-4 p-2">
                  <input type="text" className="form-control" id="inputMomSubDistrict" value={momSubDistrict} onChange={inputValue("momSubDistrict")} onBlur={(e) => cutSubDistrict(e.target.value, setState, state, 'mom')} placeholder="ระบุตำบล หรือแขวง"/>
                </div>
              </div>
              <div className="row mb-3 ps-1 pe-1">
                <label htmlFor="inputMomDistrict" className="col-sm-2 col-form-label">อำเภอ/เขต</label>
                <div className="col-sm-4 p-2">
                  <input type="text" className="form-control" id="inputMomDistrict" value={momDistrict} onChange={inputValue("momDistrict")} onBlur={(e) => cutDistrict(e.target.value, setState, state, 'mom')} placeholder="ระบุ อำเภอ หรือเขต"/>
                </div>
                <label htmlFor="inputMomProvince" className="col-sm-2 col-form-label">จังหวัด</label>
                <div className="col-sm-4 p-2">
                  <input type="text" className="form-control" id="inputMomProvince" value={momProvince} onChange={inputValue("momProvince")} onBlur={(e) => cutProvince(e.target.value, setState, state, 'mom')} placeholder="ระบุ จังหวัด"/>
                </div>
              </div>
              <div className="row mb-3 ps-1 pe-1">
                <label htmlFor="inputMomZipcode" className="bg-light col-sm-2 col-form-label">รหัสไปรษณีย์</label>
                <div className="col-sm-4 bg-light p-2">
                  <input type="text" className="form-control" id="inputMomZipcode" value={momZipcode} onChange={inputValue("momZipcode")} placeholder="กรอกรหัสไปรษณีย์"/>
                </div>
              </div>
              <div className="row-mb-3 mb-3 ps-1 pe-1">
                <label className="col-sm-2 col-form-label">สถานภาพของมารดา</label>
                <div className="btn-group" role="group">
                  <input type="radio" className="btn-check" name="mom_life" id="mom_life1" value={'1'} checked={momLife === '1'} onChange={inputValue("momLife")} autoComplete="off" required/>
                  <label className="btn btn-outline-primary" htmlFor="mom_life1">ยังมีชีวิต</label>
                  <input type="radio" className="btn-check" name="mom_life" id="mom_life2" value={'0'} checked={momLife === '0'} onChange={inputValue("momLife")} autoComplete="off"/>
                  <label className="btn btn-outline-primary" htmlFor="mom_life2">เสียชีวิต</label>
                </div>
              </div>
              <div className='row mb-3 ps-1 pe-1'>
                <label htmlFor='inputMomRace' className='col-sm-2 col-form-label'>เชื้อชาติ</label>
                <div className='col-sm-4 p-2'>
                  <input type='text' className='form-control' id='inputMomRace' value={momRace} onChange={inputValue("momRace")} placeholder="ระบุเชื้อชาติของมารดา" required/>
                </div>
                <label htmlFor='inputMomNationality' className='col-sm-2 col-form-label'>สัญชาติ</label>
                <div className='col-sm-4 p-2'>
                  <input type='text' className='form-control' id='inputMomNationality' value={momNationality} onChange={inputValue("momNationality")} placeholder="ระบุสัญชาติมารดา" required/>
                </div>
              </div>
              <div className='row mb-3 ps-1 pe-1'>
                <label htmlFor='inputMomJob' className='bg-light col-sm-2 col-form-label'>อาชีพ</label>
                <div className='col-sm-4 p-2 bg-light'>
                  <input type='text' className='form-control' id='inputMomJob' value={momJob} onChange={inputValue("momJob")} placeholder="ระบุอาชีพของมารดา" required/>
                </div>
                <label htmlFor='inputMomWorkPlace' className='bg-light col-sm-2 col-form-label'>สถานที่ทำงาน</label>
                <div className='col-sm-4 p-2 bg-light'>
                  <input type='text' className='form-control' id='inputMomWorkPlace' value={momWorkPlace} onChange={inputValue("momWorkPlace")} placeholder="ระบสถานที่ทำงานของมารดา" required/>
                </div>
              </div>
              <div className='row mb-3 ps-1 pe-1'>
                <label htmlFor='inputMomIncome' className='bg-light col-sm-2 col-form-label'>รายได้/เดือน (บาท)</label>
                <div className='col-sm-4 p-2 bg-light'>
                  <input type='text' className='form-control' id='inputMomIncome' value={momIncome} onChange={inputValue("momIncome")} placeholder="ระบุรายได้เช่น 15000" required/>
                </div>
                <label htmlFor='inputMomPhone' className='bg-light col-sm-2 col-form-label'>เบอร์โทรศัพท์</label>
                <div className='col-sm-4 p-2 bg-light'>
                  <input type='text' className='form-control' id='inputMomPhone' value={momPhone} onChange={inputValue("momPhone")} placeholder="ระบุเบอรโทรศัพท์ของมารดา" required/>
                </div>
              </div>
              {/* Parent status */}
              <div className="row mb-3 ps-1 pe-1">
                <label className="bg-light col-sm-2 col-form-label">สถานภาพของบิดา มารดา</label>
                <div className="btn-group col-sm-4 p-2 bg-light" role="group">
                <input type="radio" className="btn-check" name="radio_status" id="radio_status1" value={'togather'} checked={parentStatus === 'togather'} onChange={inputValue("parentStatus")} autoComplete="off" required/>
                  <label className="btn btn-outline-primary" htmlFor="radio_status1">อยู่ด้วยกัน</label>

                  <input type="radio" className="btn-check" name="radio_status" id="radio_status2" value={'separated'} checked={parentStatus === 'separated'} onChange={inputValue("parentStatus")} autoComplete="off"/>
                  <label className="btn btn-outline-primary" htmlFor="radio_status2">แยกกันอยู่</label>

                  <input type="radio" className="btn-check" name="radio_status" id="radio_status3" value={'divorce'} checked={parentStatus === 'divorce'} onChange={inputValue("parentStatus")} autoComplete="off"/>
                  <label className="btn btn-outline-primary" htmlFor="radio_status3">อย่าร้าง</label>
                </div>
              </div>
              <div className="row mb-3 ps-1 pe-1">
                <label htmlFor="inputSibling" className="bg-light col-sm-2 col-form-label">จำนวนพี่น้องทั้งหมด</label>
                <div className="col-sm-4 p-2 bg-light">
                  <input type="text" className="form-control" id="inputSibling" value={allSibling} onChange={inputValue("allSibling")} placeholder="ระบุจำนวนพี่น้องทั้งหมด"/>
                </div>
                <label htmlFor="inputChildOrder" className="bg-light col-sm-2 col-form-label">เป็นบุตรคนที่</label>
                <div className="col-sm-4 p-2 bg-light">
                  <input type="text" className="form-control" id="inputChildOrder" value={regChildOrder} onChange={inputValue("regChildOrder")} placeholder="บุตรคนที่"/>
                </div>
              </div>
              <div className="row mb-3 ps-1 pe-1">
                <label htmlFor="inputSiblingStudying" className="bg-light col-sm-2 col-form-label">จำนวนพี่น้องที่กำลังเรียนอยู่</label>
                <div className="col-sm-4 p-2 bg-light">
                  <input type="text" className="form-control" id="inputSiblingStudying" value={studyingSibling} onChange={inputValue("studyingSibling")} placeholder="ระบุจำนวนพี่น้องที่กำลังเรียนอยู่"/>
                </div>
              </div>
              <div className="row mb-3 ps-1 pe-1">
                <label className="bg-light col-sm-2 col-form-label">ผู้ปกครองของนักเรียน</label>
                <div className="btn-group col-sm-4 p-2 bg-light" role="group">
                  <input type="radio" className="btn-check" name="radio_parent" id="radio_parent1" value={'dad'} checked={parent === 'dad'} onChange={inputValue("parent")} autoComplete="off" required/>
                  <label className="btn btn-outline-primary" htmlFor="radio_parent1">บิดา</label>

                  <input type="radio" className="btn-check" name="radio_parent" id="radio_parent2" value={'mom'} checked={parent === 'mom'} onChange={inputValue("parent")} autoComplete="off"/>
                  <label className="btn btn-outline-primary" htmlFor="radio_parent2">มารดา</label>

                  <input type="radio" className="btn-check" name="radio_parent" id="radio_parent3" value={'other'} checked={parent === 'other'} onChange={inputValue("parent")} autoComplete="off"/>
                  <label className="btn btn-outline-primary" htmlFor="radio_parent3">บุคคลอื่นๆ</label>
                </div>
              </div>
              {/* parent informaion */}
              <hr className='text-dark mt-1 mb-3'/>
              <div hidden={isOtherParentDisabled}>
                <h5>กรณีไม่ได้อยู่กับบิดา/มารดา</h5>
                <div className="row mb-3 ps-1 pe-1">
                  <label htmlFor="inputIdCardParent" className="col-sm-2 col-form-label">เลขประจำตัวประชาชน ผู้ปกครอง</label>
                  <div className="col-sm-4 p-2">
                    <input type="text" className="form-control" id="inputIdCardParent" ref={inputIdCardParent} value={parentIdCard} onChange={inputValue("parentIdCard")} placeholder="รหัสประจำตัวประชาชน ผู้ปกครอง"/>
                  </div>
                </div>
                <div className="row mb-3 ps-1 pe-1">
                  <label htmlFor="inputParentName" className="bg-light col-sm-2 col-form-label">ชื่อ</label>
                  <div className="col-sm-4 p-2 bg-light">
                    <input type="text" className="form-control" id="inputParentName" value={parentName} onChange={inputValue("parentName")} onBlur={(e) => formatName(e.target.value, setState, state, 'parent')} placeholder="ชื่อเต็ม ผู้ปกครอง"/>
                  </div>
                  <label htmlFor="inputParentBirthdate" className="col-sm-2 col-form-label">วันเดือนปี เกิด ผู้ปกครอง</label>
                  <div className="col-sm-4 p-2">
                    <input type="text" className="form-control" id="inputParentBirthdate" value={parentBirthdate} onChange={inputValue("parentBirthdate")} placeholder="วันเดือนปีเกิด ของผู้ปกครอง"/>
                  </div>
                </div>
                <div className="row mb-3 ps-1 pe-1">
                  <label htmlFor="inputParentAddress" className="col-sm-2 col-form-label">ที่อยู่</label>
                  <div className="col-sm-4 p-2">
                    <textarea type="text" className="form-control" id="inputParentAddress" rows="3" value={parentAddress} onChange={inputValue("parentAddress")} onBlur={(e) => reAssignAddress(e.target.value, setState, state, 'parent')} placeholder="กรอกบ้านเลขที่ ถนน ซอย หมู่"></textarea>
                  </div>
                  <label htmlFor="inputParentSubDistrict" className="col-sm-2 col-form-label">ตำบล/แขวง</label>
                  <div className="col-sm-4 p-2">
                    <input type="text" className="form-control" id="inputParentSubDistrict" value={parentSubDistrict} onChange={inputValue("parentSubDistrict")} onBlur={(e) => cutSubDistrict(e.target.value, setState, state, 'parent')} placeholder="ระบุตำบล หรือแขวง"/>
                  </div>
                </div>
                <div className="row mb-3 ps-1 pe-1">
                  <label htmlFor="inputParentDistrict" className="col-sm-2 col-form-label">อำเภอ/เขต</label>
                  <div className="col-sm-4 p-2">
                    <input type="text" className="form-control" id="inputParentDistrict" value={parentDistrict} onChange={inputValue("parentDistrict")} onBlur={(e) => cutDistrict(e.target.value, setState, state, 'parent')} placeholder="ระบุ อำเภอ หรือเขต"/>
                  </div>
                  <label htmlFor="inputParentProvince" className="col-sm-2 col-form-label">จังหวัด</label>
                  <div className="col-sm-4 p-2">
                    <input type="text" className="form-control" id="inputParentProvince" value={parentProvince} onChange={inputValue("parentProvince")} onBlur={(e) => cutProvince(e.target.value, setState, state, 'parent')} placeholder="ระบุ จังหวัด"/>
                  </div>
                </div>
                <div className="row mb-3 ps-1 pe-1">
                  <label htmlFor="inputParentZipcode" className="bg-light col-sm-2 col-form-label">รหัสไปรษณีย์</label>
                  <div className="col-sm-4 bg-light p-2">
                    <input type="text" className="form-control" id="inputParentZipcode" value={parentZipcode} onChange={inputValue("parentZipcode")} placeholder="กรอกรหัสไปรษณีย์"/>
                  </div>
                  <label htmlFor="inputParentRelation" className="bg-light col-sm-2 col-form-label">มีความเกี่ยวข้องเป็น</label>
                  <div className="col-sm-4 p-2 bg-light">
                    <input type="text" className="form-control" id="inputParentRelation" value={parentRelation} onChange={inputValue("parentRelation")} placeholder="เกี่ยวข้องกับนักเรียนเป็น"/>
                  </div>
                </div>
                <div className='row mb-3 ps-1 pe-1'>
                  <label htmlFor='inputParentJob' className='bg-light col-sm-2 col-form-label'>อาชีพ</label>
                  <div className='col-sm-4 p-2 bg-light'>
                    <input type='text' className='form-control' id='inputParentJob' value={parentJob} onChange={inputValue("parentJob")} placeholder="ระบุอาชีพ"/>
                  </div>
                  <label htmlFor='inputParentWorkPlace' className='bg-light col-sm-2 col-form-label'>สถานที่ทำงาน</label>
                  <div className='col-sm-4 p-2 bg-light'>
                    <input type='text' className='form-control' id='inputParentWorkPlace' value={parentWorkPlace} onChange={inputValue("parentWorkPlace")} placeholder="ระบุสถานที่ทำงาน"/>
                  </div>
                </div>
                <div className='row mb-3 ps-1 pe-1'>
                  <label htmlFor='inputParentIncome' className='bg-light col-sm-2 col-form-label'>รายได้/เดือน (บาท)</label>
                  <div className='col-sm-4 p-2 bg-light'>
                    <input type='text' className='form-control' id='inputParentIncome' value={parentIncome} onChange={inputValue("parentIncome")} placeholder="ระบุรายได้เช่น 15000" />
                  </div>
                  <label htmlFor='inputParentPhone' className='bg-light col-sm-2 col-form-label'>เบอร์โทรศัพท์</label>
                  <div className='col-sm-4 p-2 bg-light'>
                    <input type='text' className='form-control' id='inputParentPhone' value={parentPhone} onChange={inputValue("parentPhone")} placeholder="ระบุเบอรโทรศัพท์"/>
                  </div>
                </div>
              </div>
              <h6 className="text-light bg-secondary fw-bold p-2">- กรณีฉุกเฉินสามารถติดต่อเพื่อนบ้านหรือญาติสนิท</h6>
              <hr className='text-secondary mt-1 mb-3'/>
              <div className='row mb-3 ps-1 pe-1'>
                <label htmlFor="inputEmerFname" className="bg-light col-sm-2 col-form-label">ชื่อ</label>
                <div className='col-sm-4 p-2 bg-light'>
                  <input type='text' className='form-control' id='inputEmerFname' value={emerFName} onChange={inputValue("emerFName")} placeholder='กรุณากรอกชื่อ' required/>
                </div>
                <label htmlFor="inputEmerLname" className="bg-light col-sm-2 col-form-label">นามสกุล</label>
                <div className='col-sm-4 p-2 bg-light'>
                  <input type='text' className='form-control' id='inputEmerLname' value={emerLName} onChange={inputValue("emerLName")} placeholder='กรุณากรอกนามสกุล' required/>
                </div>
              </div>
              <div className='row mb-3 ps-1 pe-1'>
                <label htmlFor="inputEmerPhone" className="bg-light col-sm-2 col-form-label">เบอร์โทรศัพท์</label>
                <div className='col-sm-4 p-2 bg-light'>
                  <input type='text' className='form-control' id='inputEmerPhone' value={emerPhone} onChange={inputValue("emerPhone")} placeholder='กรุณาเบอร์โทรศัพท์' required/>
                </div>
              </div>
              <div className="row">
                <div align="right">
                  <button type="submit" className="btn btn-success"><i className="bi bi-check-lg me-2"></i>ยืนยัน</button>
                </div>
              </div>
            </form>
          </div>
        </div>
    </div>
  )
}

export default UpdateStudentComponent