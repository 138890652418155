import './App.css';
import SidebarComponent from "./components/SidebarComponent";
import NavbarComponent from './components/NavbarComponent';
import DashboardComponent from "./components/DashboardComponent";
import RegisMatthayomComponent from "./components/RegisMatthayomComponent";
import ListMatthayomComponent from './components/ListMatthayomComponent'
import ListPrathomComponent from './components/ListPrathomComponent';
import PrintMatthayomComponent from './components/PrintMatthayomComponent';
import PrintPrathomComponent from './components/PrintPrathomComponent';
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { useEffect, useState } from 'react';
import RegistePrathomComponent from './components/RegistPrathomComponent';
import RegisterOldPrathomComponent from './components/RegisterOldPrathomComponent';
import RegisterNoDocComponent from './components/RegisterNoDocComponent';
import RegistrationReport from './reports/RegistrationReport';
import EditMatthayomComponent from './components/EditMatthayomComponent';
import EditPrathomComponent from './components/EditPrathomComponent';
import StudentInClass from './reports/StudentInClass';
import LoginRequire from './authRoute';
import PrintDocumentComponent from './components/PrintDocumentComponent';
import UpdateStudentComponent from './components/UpdateStudentComponent';

function App() {
  const [toggle, setToggle] = useState(false)
  
  const Toggle = () => {
    setToggle(!toggle)
  }

  useEffect(() => {
    
    const handleSize = () => {
      if(window.innerWidth > 768) {
        setToggle(false)
      }else{
        setToggle(true)
      }  
    }
    window.addEventListener('resize', handleSize)
    return () => {
      window.removeEventListener('resize', handleSize)
    }
  }, [])

  return (
    <BrowserRouter>
      <div className="d-flex">
        <div className={toggle ? 'd-none' : 'w-auto position-fixed'}>
          <SidebarComponent />
        </div>
        <div className={toggle ? 'd-none' : 'invisible'}>
          <SidebarComponent />
        </div>
        <div className="col overflow-auto">
          <NavbarComponent Toggle={Toggle}/>
          <Routes>
            <Route path="/" exact element={<DashboardComponent />}></Route>
            <Route path="/registerMatthayom" element={<LoginRequire><RegisMatthayomComponent /></LoginRequire>}></Route>
            <Route path="/registerPrathom" element={<LoginRequire><RegistePrathomComponent /></LoginRequire>}></Route>
            <Route path="/registerOldPrathom" element={<LoginRequire><RegisterOldPrathomComponent /></LoginRequire>}></Route>
            <Route path="/registerNoDoc" element={<LoginRequire><RegisterNoDocComponent /></LoginRequire>}></Route>
            <Route path="/listMatthayom" element={<LoginRequire><ListMatthayomComponent /></LoginRequire>}></Route>
            <Route path="/listPrathom" element={<LoginRequire><ListPrathomComponent /></LoginRequire>}></Route>
            <Route path="/printMatthayom/:st_id" element={<LoginRequire><PrintMatthayomComponent /></LoginRequire>}></Route>
            <Route path="/printPrathom/:st_id" element={<LoginRequire><PrintPrathomComponent /></LoginRequire>}></Route>
            <Route path="/registrationReport" element={<LoginRequire><RegistrationReport /></LoginRequire>}></Route>
            <Route path="/updateRegister/matthayom/:st_id" element={<LoginRequire><EditMatthayomComponent /></LoginRequire>}></Route>
            <Route path="/updateRegister/prathom/:st_id" element={<LoginRequire><EditPrathomComponent /></LoginRequire>}></Route>
            <Route path="/studentInClass" element={<LoginRequire><StudentInClass /></LoginRequire>}></Route>
            <Route path="/updateStudentInfo" element={<LoginRequire><UpdateStudentComponent /></LoginRequire>}></Route>
            <Route path="/printDocument" element={<LoginRequire><PrintDocumentComponent /></LoginRequire>}></Route>
            <Route path="/printDocument/:idnumber" element={<LoginRequire><PrintDocumentComponent /></LoginRequire>}></Route>
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  )
}

export default App;
