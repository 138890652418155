// import LoadingEffect from './LoadingEffect'
import { useEffect, useState } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import StudentInClass from '../charts/StudentInClass'
import StudentEachProvice from '../charts/StudentEachProvince'

const DashboardComponent = () => {

  // const [isLoading, setIsLoading] = useState(true)
  const [registerData, setRegisterData] = useState('')
  const [male, setMale] = useState(0)
  const [female, setFeMale] = useState(0)
  const [prathom, setPrathom] = useState(0)
  const [sum, setSum] = useState(0)

  // Function to fetch data
  const fetchData = async () => {
    axios.get(`${process.env.REACT_APP_API}/register/list`)
    .then(response => {
      setRegisterData(response.data)
    })
    .catch(err => {
      console.log(err)
      // Swal.fire({
      //   title: err.message,
      //   text: 'เกิดปัญหากับเซิฟเวอร์',
      //   icon: "error"
      // })
    })
  }

  const fetchSummary = async () => {
    axios.get(`${process.env.REACT_APP_API}/register/summary`)
    .then(response => {
      setMale(response.data.male);
      setFeMale(response.data.female);
      setPrathom(response.data.prathom);
      setSum(response.data.sum);
    })
    .catch(err => {
      console.log(err)
      // Swal.fire({
      //   title: err.message,
      //   text: 'เกิดปัญหากับเซิฟเวอร์',
      //   icon: "error"
      // })
    })
  }


  useEffect(() => {
    // Fetch data initially
    fetchData()
    fetchSummary()

    // Refresh data every 10 seconds
    const interval = setInterval(() => {
        fetchData()
        fetchSummary()
    }, 20000)

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, []); // Empty dependency array to run effect only once

  return (
    <div className="pt-4 ps-4 pe-4 mt-5 mb-4">
      <div className="container-fluid">
        <div className="row box-content rounded">
          {/* header of main content */}
          {/* {isLoading && <LoadingEffect />} */}
          <h4 className="text-dark fw-bold">
            <i className="bi bi-speedometer2 me-2"></i>
            Dashboard
          </h4>
          <hr className="text-dark mt-1 mb-3" />
          {/* body of main content */}
          <div className='row g-3 my-2'>
            <div className='col-md-3 p-1'>
              <div className='p-3 bg-warning bg-opacity-10 border-start border-warning border-5 shadow-sm d-flex justify-content-around align-items-center rounded'>
                <div>
                  <h3 className='fs-2'>{male}</h3>
                  <p className='fs-5'>มัธยม ชาย</p>
                </div>
                <i className='bi bi-person-standing p-3 fs-1'></i>
              </div>
            </div>
            <div className='col-md-3 p-1'>
              <div className='p-3 bg-info bg-opacity-10 border-start border-info border-5 shadow-sm d-flex justify-content-around align-items-center rounded'>
                <div>
                  <h3 className='fs-2'>{female}</h3>
                  <p className='fs-5'>มัธยม หญิง</p>
                </div>
                <i className='bi bi-person-standing-dress p-3 fs-1'></i>
              </div>
            </div>
            <div className='col-md-3 p-1'>
              <div className='p-3 bg-primary bg-opacity-10 border-start border-primary border-5 shadow-sm d-flex justify-content-around align-items-center rounded'>
                <div>
                  <h3 className='fs-2'>{prathom}</h3>
                  <p className='fs-5'>อนุบาล-ประถม</p>
                </div>
                <i className='bi bi-backpack2-fill p-3 fs-1'></i>
              </div>
            </div>
            <div className='col-md-3 p-1'>
              <div className='p-3 bg-success bg-opacity-10 border-start border-success border-5 shadow-sm d-flex justify-content-around align-items-center rounded'>
                <div>
                  <h3 className='fs-2'>{sum}</h3>
                  <p className='fs-5'>รวม</p>
                </div>
                <i className='bi bi-people-fill p-3 fs-1'></i>
              </div>
            </div>
          </div>
          <div className='row'>
            <StudentInClass />
            <StudentEachProvice />
          </div>
          <div className='table-responsive border-top border-secondary border-3'>
            <table className='table'>
              <thead>
                <tr>
                  <th className='fs-4' colSpan={6}>ผู้สมัครล่าสุด</th>
                </tr>
                <tr>
                  <th>เลขประจำตัว</th>
                  <th>ชื่อ-สกุล</th>
                  <th>ระดับ</th>
                  <th>ชั้น</th>
                  <th>ที่อยู่</th>
                  <th>จากโรงเรียน</th>
                </tr>
              </thead>
              <tbody>
              {Array.isArray(registerData) && registerData.length > 0 ? (
              registerData.map((data, index) => (
                <tr key={data.st_id}>
                  <td>{data.st_idnumber}</td>
                  <td>{data.st_name}</td>
                  <td>{data.regis_academicStudy}</td>
                  <td>{data.regis_academicClass}</td>
                  <td>{data.st_currentSubDistrict ? 'ต.' + data.st_currentSubDistrict : ''} {data.st_currentDistrict ? 'อ.' + data.st_currentDistrict : ''} {data.st_currentProvince ? 'จ.' + data.st_currentProvince : ''}</td>
                  <td>{data?.st_prevAcademicSchool  ?? ''}</td>
                </tr>
                ))
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center">Loading...</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardComponent;
